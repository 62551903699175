import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import auth from './modules/auth'
import password from './modules/password'
import sms from './modules/sms'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    auth,
    sms,
    password,
  },
  strict: false,
  plugins: debug ? [createLogger()] : []
})

export default store