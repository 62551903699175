<template>
  <header class="header_main_block">
    <div class="header_container">
      <div class="birge_logo" @click="goToSection('/')"></div>
      <div class="header_search">
        <input :placeholder="$t('search')" class="header_search_box" disabled/>
        <div class="header_search_glass"/>
      </div>
      <nav class="navbar_main_block">
        <!--        <router-link to="/" exact :active-class="navbarActiveLinkClass" class="navbar_main_links">-->
        <!--          {{ $t('header.main') }}-->
        <!--        </router-link>-->

        <router-link to="/" v-bind:class="[currentUrl === '/' ? navbarActiveLinkClass : '', navbarBasicLinkClass]">
          {{ $t('header.main') }}
        </router-link>

        <!--        <router-link to="/about_us" exact :active-class="navbarActiveLinkClass" class="navbar_main_links">-->
        <!--          {{ $t('header.about_us') }}-->
        <!--        </router-link>-->
        <router-link to="/courses"
                     v-bind:class="[activeCourseSection() ? navbarActiveLinkClass : '', navbarBasicLinkClass]">
          {{ $t('header.courses') }}
        </router-link>
        <router-link to="/store"
                     v-bind:class="[activeStoreSection() ? navbarActiveLinkClass : '', navbarBasicLinkClass]">
          {{ $t('header.store') }}
        </router-link>
        <router-link to="/news"
                     v-bind:class="[currentUrl.includes('news') ? navbarActiveLinkClass : '', navbarBasicLinkClass]">
          {{ $t('header.news') }}
        </router-link>
        <router-link to="/faq"
                     v-bind:class="[currentUrl.includes('faq') ? navbarActiveLinkClass : '', navbarBasicLinkClass]">
          {{ $t('header.faq') }}
        </router-link>
        <router-link to="/contacts"
                     v-bind:class="[currentUrl === '/contacts' ? navbarActiveLinkClass : '', navbarBasicLinkClass]">
          {{ $t('header.contacts') }}
        </router-link>
        <div class="dropdown_menu">
          <div class="dropbtn_menu" v-click-outside="outsideClick" @click="insideClick">{{ locale }}</div>
          <div class="dropdown_content" v-if="contentVisible">
            <a @click="switchLocale('ru')" href="#">{{ $t('ru') }}</a>
            <a @click="switchLocale('kz')" href="#">{{ $t('kz') }}</a>
          </div>
        </div>
      </nav>
      <router-link v-if="!logged" to="/signup" class="sign_up"><span>{{ $t('Registration') }}</span></router-link>
      <router-link v-if="!logged" to="/signin" class="sign_in"><span>{{ $t('Login') }}</span></router-link>
      <div v-else class="navbar_profile_dropdown_menu">
        <button
          class="sign_in_profile"
          v-click-outside="outside"
          @click="inside"
        >
          {{ $t('Profile') }} <span class="has_notifications" v-if="false"></span>
        </button>
        <div v-show="showProfileMenu" class="navbar_profile_dropdown_content">
          <div class="navbar_profile_dropdown_back">
            <router-link class="navbar__item profile" :to="'/profile'">{{ $t('My_profile') }}</router-link>
            <router-link class="navbar__item courses" :to="{name: 'my_courses', params: {type: 'active'}}">
              {{ $t('My_courses') }}
            </router-link>
            <router-link class="navbar__item notifications" :to="'/notifications_new'">
              {{ $t('Notifications') }}
            </router-link>
            <router-link class="navbar__item orders" :to="'/my_orders'">{{ $t('My_orders') }}</router-link>
            <div class="navbar_profile_dropdown_border"/>
            <div class="navbar__item logout" @click="logoutMethod">{{ $t('logout') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile_header_container">
      <div class="mobile_header_img" @click="goToSection('/')"/>
      <div class="mobile_header_lang_btn">
        <div class="dropdown_menu">
          <div class="dropbtn_menu" @click="showMobileLang = !showMobileLang">{{ locale }}</div>
          <div class="dropdown_content" v-if="showMobileLang">
            <a @click="switchLocale('ru')" href="#">{{ $t('ru') }}</a>
            <a @click="switchLocale('kz')" href="#">{{ $t('kz') }}</a>
          </div>
        </div>
        <div class="mobile_menu_btn" @click="showMenu = !showMenu"/>
      </div>
    </div>
    <div v-show="showMenu"
         class="mobile_menu_block"
    >
      <div class="mobile_menu_close_btn" @click="showMenu = false"/>
      <div class="mobile_menu_container" style="display: flex; flex-direction: column">
        <nav class="mobile_navbar_main_block">
          <div @click="goToSection('/')" class="mobile_navbar_main_links">
            {{ $t('header.main') }}
          </div>
          <!--          <div @click="goToSection('/about_us')" class="mobile_navbar_main_links">-->
          <!--            {{ $t('header.about_us') }}-->
          <!--          </div>-->
          <div @click="goToSection('/courses')" class="mobile_navbar_main_links">
            {{ $t('header.courses') }}
          </div>
          <div @click="goToSection('/store')" class="mobile_navbar_main_links">
            {{ $t('header.store') }}
          </div>
          <div @click="goToSection('/news')" class="mobile_navbar_main_links">
            {{ $t('header.news') }}
          </div>
          <div @click="goToSection('/faq')" class="mobile_navbar_main_links">
            {{ $t('header.faq') }}
          </div>

          <!--          <router-link to="/contacts" class="mobile_navbar_main_links">-->
          <!--            {{ $t('header.contacts') }}-->
          <!--          </router-link>-->
          <div class="mobile_navbar_main_links" @click="goToSection('/contacts')">
            {{ $t('header.contacts') }}
          </div>
        </nav>
        <div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin-top: 40px">
          <router-link v-if="!logged" to="/signin" class="mobile-btn"><span>{{ $t('Login') }}</span></router-link>
          <div v-if="logged" @click="goToSection('/profile')" class="mobile-btn">{{ $t('Profile') }}</div>

          <div v-if="logged" class="mobile_navbar_main_links" @click="goToSection('/profile/courses/active')">
            {{ $t('My_courses') }}
          </div>
          <div v-if="logged" class="mobile_navbar_main_links" @click="goToSection('/notifications_new')">
            {{ $t('Notifications') }}
          </div>
          <div v-if="logged" class="mobile_navbar_main_links" @click="goToSection('/my_orders')">
            {{ $t('My_orders') }}
          </div>

          <router-link v-if="!logged" to="/signup" class="mobile-btn"><span>{{ $t('Registration') }}</span>
          </router-link>
          <div v-if="logged" class="mobile-btn" @click="logoutMethod">{{ $t('logout') }}</div>
        </div>
        <div class="mobile_social_net">
          <a class="mobile_social_facebook" href="https://www.instagram.com/elbasy_academy/"></a>
          <a class="mobile_social_insta" href="https://www.instagram.com/elbasy_academy/"></a>
          <a class="mobile_social_email" href="https://www.instagram.com/elbasy_academy/"></a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>

import Vue from 'vue';
import {mapActions} from "vuex";

export default {
  name: 'Navbar',
  data() {
    return {
      locale: localStorage.getItem('locale'),
      contentVisible: false,
      navbarActiveLinkClass: 'navbar_main_link_active',
      navbarBasicLinkClass: 'navbar_main_links',
      showProfileMenu: false,
      mobile: true,
      showMenu: false,
      showMobileLang: false,
      currentUrl: '',
    }
  },
  methods: {
    switchLocale(lang) {
      this.showMobileLang = false
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      //this.$router.go(this.$router.currentRoute)
      location.reload();
    },
    logoutMethod() {
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      localStorage.removeItem('basket_id');
      localStorage.removeItem('coins');
      window.location = '/';
    },
    outside: function () {
      this.showProfileMenu = false
    },
    inside: function () {
      this.showProfileMenu = !this.showProfileMenu
    },
    outsideClick: function () {
      this.contentVisible = false
    },
    insideClick: function () {
      this.contentVisible = !this.contentVisible
      console.log(this.currentUrl)
    },
    goToSection(section) {
      this.$router.push(section)
      this.showMenu = false
    },
    activeCourseSection() {
      if (this.currentUrl.includes('course')) {
        return (this.currentUrl === 'courses' || this.currentUrl.includes('course'))
      }
    },
    activeStoreSection() {
      return this.currentUrl.includes('store') || this.currentUrl.includes('my_orders') || this.currentUrl.includes('delivery')
    }
  },
  computed: {
    logged: function () {
      let user = localStorage.getItem('user')
      let token = localStorage.getItem('token')
      return token !== null && token !== '' && user !== null && user !== ''
    },
  },
  directives: {
    'click-outside': {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.vueClickOutside = handler
        document.addEventListener('click', handler)
      },
      unbind: function (el) {
        document.removeEventListener('click', el.vueClickOutside)
        el.vueClickOutside = null
      }
    }
  },
  watch: {
    '$route'(to, from) {
      this.currentUrl = to.path
    }
  },
  mounted() {

    if(localStorage.getItem('has_email')=='false'){
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      localStorage.removeItem('has_email')
      location.reload()
    }


    this.currentUrl = this.$route.path
  }
}
</script>


