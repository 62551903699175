import Vue from "vue";
import VueResource from "vue-resource";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

switch (window.location.hostname) {
  case "localhost":
    // window.API_ROOT = "http://localhost:8000";
    window.CLIENT_ROOT = "http://localhost:8080"
    window.API_ROOT = 'https://api.birgeoqy.kz'
    // window.API_ROOT = 'http://smart-oqu.loc'
    break;
  case "birgeoqu.elumiti.kz":
    window.API_ROOT = "https://apibirgeoqu.elumiti.kz";
    window.CLIENT_ROOT = "https://birgeoqu.elumiti.kz"
    break;
  case "birgeoqy.kz":
    window.API_ROOT = "https://api.birgeoqy.kz";
    window.CLIENT_ROOT = "https://birgeoqy.kz"
    break;
  default:
    window.API_ROOT = "https://api.birgeoqy.kz";
    window.CLIENT_ROOT = "https://birgeoqy.kz"
}

Vue.use(VueResource);

Vue.http.options.crossOrigin = true;
Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.root = API_ROOT;
// Vue.http.options.emulateJSON = true

Vue.http.options.progress = function (event) {
  if (event.lengthComputable) {
    NProgress.set(event.loaded / event.total);
  }
};

Vue.http.interceptors.push((request, next) => {
  NProgress.start();
  if (localStorage.getItem("session_id")) {
    request.headers.set("session-id", localStorage.getItem("session_id"));
  }

  if (localStorage.getItem("token")) {
    request.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  }
  const currentUrl = window.location.pathname

  next((response) => {
    NProgress.done();
    if (response.status > 400 && !response.body.need_verification && currentUrl != '/signup') {

      Vue.toastr({
        description: response.body.message
          ? response.body.message
          : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
        type: "error",
      });
    }
    if (response.status == 401) {
      localStorage.setItem("session_id", "");
      localStorage.setItem("token", "");
      localStorage.setItem("user", "");
      window.location = "/";
    }
    return response;
  });
});

// export const GetIIN = Vue.resource(API_ROOT + "/api/iin");
// export const GetIINByEmail = Vue.resource(API_ROOT + "/api/iinbyemail");
export const Auth = Vue.resource(API_ROOT + "/api/auth/login");
// export const SendSMS = Vue.resource(API_ROOT + "/api/sms");
