import * as API from '@/api'

const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS'
const SEND_SMS_FAIL = 'SEND_SMS_FAIL'

const getters = {}

const mutations = {
  [SEND_SMS_SUCCESS] (state, data) {
  },
  [SEND_SMS_FAIL] (state) {
  },
}

const actions = {
  // sendSms({commit}, data) {
  //   return API.SendSMS.save(data)
  //     .then((res) => {
  //       // commit(AUTH_CHECK_IIN_SUCCESS, res.body)
  //     })
  //     .catch((res) => {
  //       // commit(AUTH_CHECK_IIN_FAIL)
  //       return Promise.reject(res)
  //     })
  // },
}

export default {
  getters,
  mutations,
  actions
}