import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default [
  {
    path: '/',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Home/Index'),
    },
    meta: {
      title: 'Образовательная платформа |  Birge Oqý',
      metaTags: [
        {
          name: 'og:description',
          content: 'Приветствуем тебя на нашей образовательной платформе Birge Oqy!'
        },
        {
          name: 'og:image',
          content: 'https://birgeoqy.kz/images/smartoqu/logo.png'
        },
      ]
    }
  },
  {
    name: 'signin',
    path: '/signin',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Auth/Signin')
    }
  },
  {
    name: 'profile',
    path: '/profile',
    meta: { requiresAuth: true, role: 'student' },
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/ProfileStudent/index')
    },
  },
  {
    name : 'my_courses',
    path: '/profile/courses/:type',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/MyCourses')
    }
  },
  {
    name: 'courses',
    path: '/courses',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/Courses'),
    },
  },
  {
    name: 'course-details',
    path: '/course/:slug',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/CourseDetails')
    },
    props: true
  },
  {
    name: 'about-course',
    path: '/course/:slug/about',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/AboutCourse')
    }
  },
  {
    name: 'course-module',
    path: '/course/:slug/module/:module',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/CourseModule')
    }
  },
  {
    name: 'course-review',
    path: '/course/:slug/review/:course',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/CourseReview')
    }
  },
  {
    name: 'course-theme',
    path: '/course/:slug/module/:module/theme/:theme',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/CourseTheme')
    }
  },
  {
    name: 'final-testing',
    path: '/course/:slug/final',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/FinalTesting')
    }
  },
  {
    name: 'course-exercise',
    path: '/course/:slug/exercise/:quiz',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/CourseExercise')
    }
  },
  {
    name: 'course-final-exercise',
    path: '/course/:slug/final-exercise/:quiz',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/CourseFinalExercise')
    }
  },
  /*
   *
   */
   {
     name: 'signup',
     path: '/signup/:tariff?',
     props: (route) => ({ from: route.params.username }),
     components: {
       header: Navbar,
       footer: Footer,
       default: () => import('@/views/Auth/Signup/Signup.vue')
     }
   },
  {
    path: '/reset_password',
    components: {
      //header: Navbar,
      //footer: Footer,
      default: () => import('@/views/Auth/ResetPassword')
    }
  },
  {
    path: '/recovery_password',
    components: {
      //header: Navbar,
      //footer: Footer,
      default: () => import('@/views/Auth/ForgetPassword/Email')
    }
  },
  {
    path: '/profile',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/ProfileStudent/index')
    }
  },
  {
    path: '/profile_settings/:section',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/ProfileStudent/Profile_settings')
    }
  },
  {
    path: '/profile_tutor',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/ProfileStudent/Tutor')
    }
  },
  {
    name: 'news',
    path: '/news',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/News/News')
    }
  },
  {
    name: 'news-item',
    path: '/news/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/News/NewsItem')
    }
  },
  {
    name: 'courses-index',
    path: '/courses',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/Courses')
    }
  },
  {
    path: '/my_courses/active',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/MyCourses_active')
    }
  },
  {
    path: '/theme_one_video',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ThemeOneVideo')
    }
  },
  {
    name: 'Presentation',
    path: '/presentation/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/Presentation'),
    },
  },
  {
    path: '/theme_one_essay',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ThemeOneEssay')
    }
  },
  {
    path: '/theme_one_exercises_good',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ThemeOneExercisesGood')
    }
  },
  {
    path: '/theme_one_exercises_bad',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ThemeOneExercisesBad')
    }
  },
  {
    path: '/module_testing_good',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ModuleTestingGood')
    }
  },
  {
    path: '/module_testing_bad',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ModuleTestingBad')
    }
  },
  {
    path: '/review',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/Review')
    }
  },
  {
    path: '/review_text',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/ReviewText')
    }
  },
  {
    path: '/final_testing_good',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/FinalTestingGood')
    }
  },
  {
    path: '/final_testing_bad',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/FinalTestingBad')
    }
  },
  {
    path: '/feedback',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/Feedback')
    }
  },
  // {
  //   name : 'notifications',
  //   path: '/notifications',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Courses/Notifications')
  //   }
  // },
  {
    name : 'notifications_new',
    path: '/notifications_new',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Courses/NotificationsNew')
    }
  },
  // {
  //   path: '/notifications_read',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Courses/NotificationsRead')
  //   }
  // },
  // {
  //   path: '/notifications_settings',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Courses/NotificationsSettings')
  //   }
  // },
  {
    path: '/faq',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/FAQ/FAQCategories')
    }
  },
  {
    name: 'faq-item',
    path: '/faq/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/FAQ/FAQ')
    }
  },
  {
    path: '/contacts',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Contacts/Contacts')
    }
  },
  {
    path: '/store',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Store/Store')
    }
  },
  {
    path: '/store/basket',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Store/Basket')
    }
  },
  // {
  //   path: '/store_one_product',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Store/StoreOneProduct')
  //   }
  // },
  {
    name: 'goods-details',
    path: '/store/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Store/StoreOneProduct')
    }
  },
  // {
  //   path: '/delivery',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Store/Delivery')
  //   }
  // },
  {
    path: '/my_orders',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Store/MyOrders')
    }
  },
  {
    name: 'success',
    path: '/success',
    components: {
      default: () => import('@/views/Auth/Signup/Success.vue')
    }
  },
  {
    path: '/confirm_email',
    components: {
      //header: Navbar,
      //footer: Footer,
      default: () => import('@/views/Auth/Signup/ConfirmEmail.vue')
    }
  },
  {
    name: 'not-found',
    path: '*',
    components: {
      header: Navbar,
      footer: Footer,
      default: Empty
    }
  }
]
