import * as API from '@/api'

const AUTH_CHECK_IIN_SUCCESS = 'AUTH_CHECK_IIN_SUCCESS'
const AUTH_CHECK_IIN_FAIL = 'AUTH_CHECK_IIN_FAIL'
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'
const AUTH_LOGOUT = 'AUTH_LOGOUT'
const LOADING_AUTH = 'LOADING_AUTH'
const SWITCH_TECH = 'SWITCH_TECH'
const SET_TIME = 'SET_TIME'

const state = {
  token: localStorage.getItem('token') || '',
  user: localStorage.getItem('user') || '',
  data: sessionStorage.getItem('children') || '',
  loading: false,
  tech: false,
}

const getters = {
  isLoading(state) {
    return state.loading
  },

  getTime(state) {
    return state.time
  }
}

const mutations = {
  [AUTH_CHECK_IIN_SUCCESS] (state, data) {
    if (data.data) {
      sessionStorage.setItem('children', JSON.stringify(data.data))
      state.data = data.data;
    }
  },
  [AUTH_CHECK_IIN_FAIL] (state) {
    state.data = ''
    sessionStorage.setItem('children', '')
  },
  [AUTH_LOGIN_SUCCESS] (state, data) {
    state.token = data.token
    state.user = data.user;
    localStorage.setItem('token', data.token)
    localStorage.setItem('user', JSON.stringify(data.user))
  },
  // [AUTH_LOGIN_FAIL] (state, err) {
  [AUTH_LOGIN_FAIL] (state) {
    state.token = ''
    state.user = ''
    localStorage.setItem('token', '')
    localStorage.setItem('user', '')
  },
  //[AUTH_LOGOUT] (state, err) {
  [AUTH_LOGOUT] (state) {
    state.token = ''
    state.user = ''
    localStorage.setItem('token', '')
    localStorage.setItem('user', '')
  },
  [LOADING_AUTH] (state, data) {
    state.loading = data
  },
  [SWITCH_TECH] (state, data) {
    state.tech = data
  },
  [SET_TIME] (state, data) {
    state.time = data
  }
}

const actions = {
  login ({commit}, data) {
    commit(LOADING_AUTH, true)
    return API.Auth.save(data)
      .then((res) => {
        commit(AUTH_LOGIN_SUCCESS, res.body)
        commit(LOADING_AUTH, false)
      })
      .catch((res) => {
        // if(res.status !== 403) {
        //   alert("Авторизация не прошла. Возможно, высокая нагрузка на сайт или низкая скорость интернета. Попробуйте еще раз.")
        // }
        commit(LOADING_AUTH, false)
        commit(AUTH_LOGIN_FAIL)
        return Promise.reject(res)
      })
  },
  // getIin({commit}, data) {
  //   return API.GetIIN.save(data)
  //     .then((res) => {
  //       commit(AUTH_CHECK_IIN_SUCCESS, res.body)
  //     })
  //     .catch((res) => {
  //       commit(AUTH_CHECK_IIN_FAIL)
  //       return Promise.reject(res)
  //     })
  // },
  // getIinByEmail({commit}, data) {
  //   return API.GetIINByEmail.save(data)
  //     .then((res) => {
  //       commit(AUTH_CHECK_IIN_SUCCESS, res.body)
  //     })
  //     .catch((res) => {
  //       commit(AUTH_CHECK_IIN_FAIL)
  //       return Promise.reject(res)
  //     })
  // },
  // logout ({commit}) {
  //   return API.Logout.save()
  //     .then((res) => {
  //       commit(AUTH_LOGOUT)
  //     })
  //     .catch((res) => {
  //       commit(AUTH_LOGOUT)
  //       return Promise.reject(res)
  //     })
  // },
  setTech({commit}, data) {
    commit(SWITCH_TECH, data)
  },
  // getTech({commit}) {
  //   fetch('https://test.elumiti.kz/api/technical-break/status')
  //   .then((res) => {
  //     return res.json();
  //   })
  //   .then((data) => {
  //     commit(SWITCH_TECH, data.data.technical_break === 1 ? true : false)
  //     commit(SET_TIME, data.data.technical_break_timeout)
  //   })
  //   .catch((e) => {
  //     console.log(e)
  //   })
  // }
}

export default {
  state,
  getters,
  mutations,
  actions
}