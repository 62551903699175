<template>
  <footer class="footer_main_block">
    <div class="footer_space">
      <div class="footer_container">
        <nav class="nav_footer_links">
          <a v-if="$i18n.locale === 'ru'" href="/documents/policy.pdf" target="_blank">{{ $t('Privacy_policy') }}</a>
          <a v-if="$i18n.locale === 'kz'" href="/documents/policy_kz.pdf" target="_blank">{{ $t('Privacy_policy') }}</a>
          <a v-if="$i18n.locale === 'ru'" href="/documents/offer.pdf" target="_blank">{{ $t('Public_offer_agreement') }}</a>
          <a v-if="$i18n.locale === 'kz'" href="/documents/offer_kz.pdf" target="_blank">{{ $t('Public_offer_agreement') }}</a>
          <a v-if="$i18n.locale === 'ru'" href="/documents/privacy.pdf" target="_blank"><span v-html="$t('Agreement_on_the_protection_of_personal_data_of_users')"></span></a>
          <a v-if="$i18n.locale === 'kz'" href="/documents/privacy_kz.pdf" target="_blank"><span v-html="$t('Agreement_on_the_protection_of_personal_data_of_users')"></span></a>
          <a v-if="$i18n.locale === 'ru'" href="/documents/store_provisions_rus.pdf" target="_blank">{{ $t('store_provisions') }}</a>
          <a v-if="$i18n.locale === 'kz'" href="/documents/store_provisions_kaz.pdf" target="_blank">{{ $t('store_provisions') }}</a>
<!--          <router-link to="#">{{ $t('header.about_us') }}</router-link>-->
<!--          <a href="">{{ $t('header.about_us') }}</a>-->
        </nav>
        <div class="footer_logo"></div>
        <div class="footer_social">
          <div class="footer_social_app_google">
            <div class="footer_app_store"/>
            <div class="footer_google_play"/>
          </div>
          <div class="footer_social_net">
<!--            <a class="social_footer_facebook" href="https://www.facebook.com/elumiti?mibextid=LQQJ4d" target="_blank"></a>-->
            <a class="social_footer_insta" href="https://www.instagram.com/olimpiada_elumiti.kz/" target="_blank"></a>
            <a class="social_footer_email" href="https://t.me/+WPGK6KlglZs3NzZh" target="_blank"></a>
          </div>

        </div>
      </div>
      <div class="footer_copyright">Copyright © 2022 Smart Ustaz</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',

}
</script>

<style>

</style>
